import { graphql, useStaticQuery } from "gatsby"
import { Ingress } from "../components/Styling/Body"

/**
 * @description ReportIngress
 * @param param0
 */

const ReportIngress = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulMinside(slug: { eq: "report-ingress" }) {
        id
        ingress {
          ingress
        }
      }
    }
  `)

  const { contentfulMinside: content } = data

  return (
    <div>
      <Ingress>{content.ingress?.ingress}</Ingress>
    </div>
  )
}

export default ReportIngress
